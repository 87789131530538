<template>
  <div class="main-wrapper" v-if="prescriptionDetails">
    <layout-header></layout-header>
    <div class="breadcrumb-bar">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-12 col-12">
            <h2 class="breadcrumb-title">
              {{ $t("provider.prescriptionDetails.pageTitle") }}:
              {{ prescriptionDetails.id }}
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="card mb-2" style="font-size: 12px">
                  <div class="card-header">
                    <h4>
                      {{ $t("provider.prescriptionDetails.reqDate") }}:
                      {{
                        new Date(
                          prescriptionDetails.created_at
                        ).toLocaleDateString()
                      }}
                    </h4>
                  </div>
                </div>

                <div
                  class="accordion"
                  id="prescription-accordion"
                  style="font-size: 12px"
                >
                  <!-- PATIENT INFORMATION -->
                  <div class="card mb-0" v-if="prescriptionDetails.patient">
                    <div class="card-header" id="patient-info-accordion">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#patient-info-collapse"
                          aria-expanded="true"
                          aria-controls="patient-info-collapse"
                        >
                          <i class="fas fa-user mr-1"></i>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.title"
                            )
                          }}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="patient-info-collapse"
                      class="collapse show"
                      aria-labelledby="patient-info-accordion"
                      data-parent="#prescription-accordion"
                    >
                      <div class="card-body">
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.name"
                            )
                          }}
                          : {{ prescriptionDetails.patient.first_name }}
                          {{ prescriptionDetails.patient.last_name }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.dateOfBirth"
                            )
                          }}:
                          {{ prescriptionDetails.patient.dob }}
                          <!-- ({{
                          getAge(prescriptionDetails.patient.dob)
                          }}
                          Years) -->
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.gender"
                            )
                          }}:
                          {{ prescriptionDetails.patient.gender }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.height"
                            )
                          }}:
                          {{ String(prescriptionDetails.patient.height) }} IN
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.weight"
                            )
                          }}:
                          {{ String(prescriptionDetails.patient.weight) }} LB
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.mobile"
                            )
                          }}
                          :
                          {{ prescriptionDetails.patient.primary_phone }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.patientInformation.address"
                            )
                          }}
                          : {{ prescriptionDetails.patient.address1 }},
                          {{ prescriptionDetails.patient.address2 }},
                          {{ prescriptionDetails.patient.city }},
                          {{ prescriptionDetails.patient.state }} -
                          {{ prescriptionDetails.patient.zip_code }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- PHARMACY INFORMATION -->
                  <div class="card mb-0" v-if="prescriptionDetails.pharmacy">
                    <div class="card-header" id="pharmacy-info-accordion">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#pharmacy-info-collapse"
                          aria-expanded="false"
                          aria-controls="pharmacy-info-collapse"
                        >
                          <i class="fas fa-hospital mr-1"></i>
                          {{
                            $t(
                              "provider.prescriptionDetails.pharmacyDetails.title"
                            )
                          }}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="pharmacy-info-collapse"
                      class="collapse"
                      aria-labelledby="pharmacy-info-accordion"
                      data-parent="#prescription-accordion"
                    >
                      <div class="card-body">
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.pharmacyDetails.storeId"
                            )
                          }}:
                          {{ prescriptionDetails.pharmacy.pharmacy_id }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.pharmacyDetails.storeName"
                            )
                          }}:
                          {{ prescriptionDetails.pharmacy.pharmacy_name }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.pharmacyDetails.phone"
                            )
                          }}: {{ prescriptionDetails.pharmacy.phone }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.pharmacyDetails.address"
                            )
                          }}: {{ prescriptionDetails.pharmacy.address1 }},
                          {{ prescriptionDetails.pharmacy.address2 }},
                          {{ prescriptionDetails.pharmacy.city }},
                          {{ prescriptionDetails.pharmacy.state }} -
                          {{ prescriptionDetails.pharmacy.zip_code }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- HEALTH & SYMPTOMS INFORMATION -->
                  <div
                    class="card mb-0"
                    v-if="prescriptionDetails.observations"
                  >
                    <div class="card-header" id="symptoms-info-accordion">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#symptoms-info-collapse"
                          aria-expanded="false"
                          aria-controls="symptoms-info-collapse"
                        >
                          <i class="fas fa-notes-medical mr-1"></i>
                          {{ $t("provider.prescriptionDetails.description") }}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="symptoms-info-collapse"
                      class="collapse"
                      aria-labelledby="symptoms-info-accordion"
                      data-parent="#prescription-accordion"
                    >
                      <div class="card-body">
                        {{ prescriptionDetails.observations.symptoms }}
                      </div>
                    </div>
                  </div>

                  <!-- ALLERGIES INFORMATION -->
                  <div
                    class="card mb-0"
                    v-if="prescriptionDetails.observations.allergies"
                  >
                    <div class="card-header" id="allergies-info-accordion">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#allergies-info-collapse"
                          aria-expanded="false"
                          aria-controls="allergies-info-collapse"
                        >
                          <i class="fas fa-allergies mr-1"></i>
                          {{ $t("provider.prescriptionDetails.allergies") }}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="allergies-info-collapse"
                      class="collapse"
                      aria-labelledby="allergies-info-accordion"
                      data-parent="#prescription-accordion"
                    >
                      <div class="card-body">
                        {{ prescriptionDetails.observations.allergies }}
                      </div>
                    </div>
                  </div>

                  <!-- HISTORY INFORMATION -->
                  <div
                    class="card mb-0"
                    v-if="prescriptionDetails.observations.history"
                  >
                    <div class="card-header" id="history-info-accordion">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#history-info-collapse"
                          aria-expanded="false"
                          aria-controls="history-info-collapse"
                        >
                          <i class="fas fa-history mr-1"></i>
                          {{
                            $t("provider.prescriptionDetails.medicalHistory")
                          }}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="history-info-collapse"
                      class="collapse"
                      aria-labelledby="history-info-accordion"
                      data-parent="#prescription-accordion"
                    >
                      <div class="card-body">
                        {{ prescriptionDetails.observations.history }}
                      </div>
                    </div>
                  </div>

                  <!-- HEALTH INFORMATION -->
                  <div class="card" v-if="prescriptionDetails.dosageInfo">
                    <div class="card-header" id="health-info-accordion">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#health-info-collapse"
                          aria-expanded="false"
                          aria-controls="health-info-collapse"
                        >
                          <i class="fas fa-heart mr-1"></i>
                          {{
                            $t(
                              "provider.prescriptionDetails.healthInformation.title"
                            )
                          }}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="health-info-collapse"
                      class="collapse"
                      aria-labelledby="health-info-accordion"
                      data-parent="#prescription-accordion"
                    >
                      <div
                        class="card-body"
                        v-if="prescriptionDetails.dosageInfo"
                      >
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.healthInformation.currentMedications"
                            )
                          }}:
                          {{
                            prescriptionDetails.dosageInfo.currentMedications
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.healthInformation.knownAllergies"
                            )
                          }}:
                          {{ prescriptionDetails.dosageInfo.knownAllergies }}
                        </p>
                        <p>
                          {{
                            $t(
                              "provider.prescriptionDetails.healthInformation.reasonForMedication"
                            )
                          }}:
                          {{
                            prescriptionDetails.dosageInfo.reasonForMedication
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- MANAGE MEDICINES & RX CHANGES -->
              <div
                class="col-md-9"
                v-if="
                  medicineDetails.length > 0 &&
                  prescriptionDetails.pharmacy &&
                  prescriptionDetails.status !== 'Cancelled'
                "
              >
                <!-- Title and Header -->
                <div class="card m-0" style="font-size: 12px">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-2">
                        <h4>
                          {{
                            $t("provider.prescriptionDetails.medications.title")
                          }}
                        </h4>
                      </div>
                      <div class="col-10">
                        <div class="text-right">
                          <button
                            class="btn btn-sm btn-outline-info mr-2"
                            @click="markChatRead"
                          >
                            <i class="fa fa-comment mr-1"></i>
                            {{
                              $t(
                                "provider.prescriptionDetails.medications.chat"
                              )
                            }}
                            <span
                              v-if="unreadMessages > 0"
                              class="badge badge-pill bg-danger-light"
                            >
                              {{ unreadMessages }} New</span
                            >
                          </button>
                          <button
                            class="btn btn-sm btn-outline-info mr-2"
                            @click="showModal('select-medicine-modal')"
                            v-show="
                              prescriptionDetails.status === 'In Progress'
                            "
                          >
                            {{
                              $t(
                                "provider.prescriptionDetails.medications.changeMedicine"
                              )
                            }}
                          </button>
                          <button
                            class="btn btn-sm btn-outline-info mr-2"
                            @click="showModal('select-pharmacy-modal')"
                            v-show="
                              prescriptionDetails.status === 'In Progress'
                            "
                          >
                            {{
                              $t(
                                "provider.prescriptionDetails.medications.changePharmacy"
                              )
                            }}
                          </button>
                          <button
                            @click="showRejectPrescriptionModal"
                            v-if="
                              !isEditing &&
                              prescriptionDetails.status !== 'Cancelled'
                            "
                            class="btn btn-sm bg-danger-light mr-2"
                          >
                            <i class="fas fa-times"></i>
                            {{
                              $t(
                                "provider.prescriptionDetails.medications.cancelRequest"
                              )
                            }}
                          </button>
                          <button
                            v-if="
                              !isEditing &&
                              !isError &&
                              prescriptionDetails.status === 'In Progress'
                            "
                            @click="showAcceptPrescriptionModal"
                            class="btn btn-sm bg-success-light mr-2"
                          >
                            <i class="fas fa-check"></i>
                            {{
                              $t(
                                "provider.prescriptionDetails.medications.approveRequest"
                              )
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Medicines -->
                <div class="mt-2" style="font-size: 12px">
                  <div
                    class="accordion"
                    style="height: 600px; overflow: auto"
                    id="medicine-accordion"
                  >
                    <div
                      class="card mb-0"
                      v-for="(medicine, i) in medicineDetails"
                      :key="i"
                    >
                      <div
                        class="card-header"
                        :id="`medicine-accordion-${medicine.id}`"
                      >
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-sm btn-block text-left"
                            type="button"
                            data-toggle="collapse"
                            :data-target="`#medicine-collapse-${medicine.id}`"
                            aria-expanded="true"
                            :aria-controls="`medicine-collapse-${medicine.id}`"
                            @click="isEditingRequired(medicine)"
                          >
                            {{ i + 1 }}.
                            {{
                              !medicine.selected_refill
                                ? medicine.medicine_name
                                : medicine.selected_refill.medicine_name
                            }}
                            <div
                              class="float-right"
                              v-if="medicine.dsp_cancel_status == 'Completed'"
                            >
                              <span
                                class="badge bg-danger-light"
                                title="Prescription Cancelled"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.cancelled"
                                  )
                                }}</span
                              >
                            </div>
                            <div v-else class="float-right">
                              <span
                                v-if="
                                  !medicine.directions ||
                                  medicine.directions === ''
                                "
                                :id="`status-fail-${medicine.ndc}`"
                                class="badge bg-warning-light ml-1"
                                title="Incomplete details"
                              >
                                {{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.detailsIncomplete"
                                  )
                                }}
                              </span>
                              <span
                                v-if="
                                  medicine.is_refill_initiated &&
                                  medicine.dsp_status_id === 13
                                "
                                :id="`status-fail-${medicine.ndc}`"
                                class="badge bg-success-light ml-1"
                                title="Pharmacy verified details"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.pharmacyVerified"
                                  )
                                }}</span
                              >
                              <span
                                v-if="
                                  !medicine.is_refill_initiated &&
                                  !medicine.is_rxchange_requested &&
                                  medicine.dsp_status_id === 13
                                "
                                :id="`status-fail-${medicine.ndc}`"
                                class="badge bg-warning-light ml-1"
                                title="WAITING FOR PHARMACY RESPONSE"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.waitingForPharmacyRes"
                                  )
                                }}</span
                              >
                              <span
                                v-if="
                                  medicine.is_refill_initiated &&
                                  medicine.selected_refill &&
                                  medicine.selected_refill.status ===
                                    'Completed'
                                "
                                :id="`status-refill-approved-${medicine.ndc}`"
                                class="badge bg-success-light ml-1"
                                title="Refill Completed"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.pharmacyRefillCompleted"
                                  )
                                }}</span
                              >
                              <span
                                v-if="medicine.rxchange_status === 'Completed'"
                                :id="`status-rxchange-${medicine.ndc}`"
                                class="badge bg-success-light ml-1"
                                title="Pharmacy RxChange Completed"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.pharmacyRxChangeCompleted"
                                  )
                                }}</span
                              >
                              <span
                                v-if="
                                  medicine.is_refill_initiated &&
                                  medicine.selected_refill &&
                                  medicine.refill_status === 'Ready'
                                "
                                :id="`status-refill-ready-${medicine.ndc}`"
                                class="badge bg-success-light ml-1"
                                title="Pharmacy Refill Ready"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.pharmacyRefillReady"
                                  )
                                }}</span
                              >
                              <span
                                v-if="
                                  medicine.is_refill_initiated &&
                                  medicine.selected_refill &&
                                  medicine.refill_status === 'Denied'
                                "
                                :id="`status-refill-ready-${medicine.ndc}`"
                                class="badge bg-danger-light ml-1"
                                title="Pharmacy Refill Denied"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.pharmacyRefillDenied"
                                  )
                                }}</span
                              >
                              <span
                                v-if="
                                  medicine.is_rxchange_requested &&
                                  medicine.selected_rxchange &&
                                  medicine.selected_rxchange.status ===
                                    'Pending'
                                "
                                :id="`status-fail-${medicine.ndc}`"
                                class="badge bg-danger-light ml-1"
                                title="Rx Change Requested"
                                >{{
                                  $t(
                                    "provider.prescriptionDetails.medications.medicines.pharmacyRxChangeReq"
                                  )
                                }}</span
                              >
                            </div>
                            <br />
                            <span
                              v-if="medicine.pin"
                              class="m-2"
                              style="font-size: 11px"
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.pin"
                                )
                              }}:
                              {{
                                medicine.pin ? medicine.pin : "Not Generated"
                              }}</span
                            >
                            <span
                              v-if="
                                medicine.selected_refill &&
                                prescriptionDetails.status == 'Completed'
                              "
                              class="m-2"
                              style="font-size: 11px"
                              >Approved Refill ID:
                              {{ medicine.selected_refill.refill_id }}</span
                            >
                            <span
                              v-if="
                                medicine.selected_refill &&
                                prescriptionDetails.status == 'Completed'
                              "
                              class="m-2"
                              style="font-size: 11px"
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.writtenDate"
                                )
                              }}:
                              {{
                                new Date(
                                  medicine.selected_refill.requested_date
                                ).toLocaleString()
                              }}</span
                            >
                            <span
                              v-if="
                                (prescriptionDetails.status == 'Cancelled' ||
                                  prescriptionDetails.status == 'Accepted') &&
                                medicine.dsp_cancel_message
                              "
                              class="m-2"
                              style="font-size: 11px"
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.pharmacyMessage"
                                )
                              }}:
                              <strong>{{
                                medicine.dsp_cancel_message
                                  ? medicine.dsp_cancel_message
                                  : "Unknown"
                              }}</strong></span
                            >
                            <span
                              v-if="
                                medicine.rxChange &&
                                medicine.is_rxchange_requested
                              "
                              class="m-2"
                              style="font-size: 11px"
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.pharmacyMessage"
                                )
                              }}:
                              <span class="text-danger">
                                {{
                                  getRxChangeMessage(
                                    medicine.rxChange.rxChangeType
                                  )
                                }}</span
                              >
                            </span>
                          </button>
                        </h2>
                      </div>

                      <div
                        :id="`medicine-collapse-${medicine.id}`"
                        class="collapse"
                        :aria-labelledby="`medicine-accordion-${medicine.id}`"
                        data-parent="#medicine-accordion"
                      >
                        <div
                          class="card-body"
                          style="height: 525px; overflow: auto"
                        >
                          <div class="row">
                            <!-- Rx Change Header Message -->
                            <div
                              class="col-12"
                              v-if="
                                isSpecialRxChange(medicine.selected_rxchange)
                              "
                            >
                              <!-- Out of Stock -->
                              <div
                                class="card mb-3"
                                v-if="isOutOfStock(medicine.selected_rxchange)"
                              >
                                <div class="card-body">
                                  <h5 class="text-center">
                                    {{
                                      $t(
                                        "provider.prescriptionDetails.medications.medicines.pharmacyMessage"
                                      )
                                    }}:
                                    {{
                                      $t(
                                        "provider.prescriptionDetails.medications.medicines.outOfStock"
                                      )
                                    }}
                                  </h5>
                                  <div class="text-center">
                                    <p>
                                      {{
                                        $t(
                                          "provider.prescriptionDetails.medications.medicines.stockDescription"
                                        )
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- Old medicine details -->
                            <div class="col-6">
                              <!-- RX Changes -->
                              <div
                                class="card"
                                v-if="
                                  !isSpecialRxChange(
                                    medicine.selected_rxchange
                                  ) &&
                                  medicine.selected_rxchange &&
                                  medicine.is_rxchange_requested &&
                                  medicine.selected_rxchange.status ===
                                    'Pending'
                                "
                              >
                                <div class="card-header">
                                  <h5
                                    v-if="
                                      medicine.selected_rxchange
                                        .rxchange_type === 'Prior Authorization'
                                    "
                                  >
                                    {{
                                      $t(
                                        "provider.prescriptionDetails.medications.medicines.actionRequired"
                                      )
                                    }}
                                  </h5>
                                  <h5
                                    v-if="
                                      medicine.selected_rxchange
                                        .rxchange_type ===
                                      'Script Clarification'
                                    "
                                  >
                                    {{
                                      $t(
                                        "provider.prescriptionDetails.medications.medicines.verificationRequired"
                                      )
                                    }}
                                  </h5>
                                  <h5 v-else>RxChange Request Message</h5>
                                </div>
                                <div class="card-body">
                                  <!-- Prior Auth Number -->
                                  <form
                                    v-if="
                                      medicine.selected_rxchange
                                        .rxchange_type === 'Prior Authorization'
                                    "
                                    @submit.prevent="
                                      approveRxChangeRequest(
                                        medicine.selected_rxchange
                                          .rxchange_request_id,
                                        medicine.selected_rxchange
                                          .prescribed_dsp_prescription_id,
                                        medicine.dsp_prescription_id
                                      )
                                    "
                                  >
                                    <div class="form-group text-left">
                                      <label
                                        ><strong>{{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.authNumber"
                                          )
                                        }}</strong></label
                                      >
                                      <input
                                        type="number"
                                        class="form-control form-control-xs"
                                        v-model="priorAuthNumber"
                                        maxlength="35"
                                        required
                                      />
                                    </div>
                                    <div class="row">
                                      <div class="col-6">
                                        <button
                                          class="btn btn-sm bg-warning-light btn-block"
                                        >
                                          {{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.denyRxChange"
                                            )
                                          }}
                                        </button>
                                      </div>
                                      <div class="col-6">
                                        <button
                                          type="submit"
                                          class="btn btn-sm bg-success-light btn-block"
                                        >
                                          {{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.approveRxChange"
                                            )
                                          }}
                                        </button>
                                      </div>
                                    </div>
                                  </form>

                                  <!-- Script Clarification -->
                                  <div
                                    v-if="
                                      medicine.selected_rxchange
                                        .rxchange_type ===
                                      'Script Clarification'
                                    "
                                  >
                                    <p>
                                      {{
                                        $t(
                                          "provider.prescriptionDetails.medications.medicines.verifyDescription"
                                        )
                                      }}
                                    </p>
                                  </div>

                                  <div v-else>
                                    <form
                                      @submit.prevent="
                                        approveRxChangeRequest(
                                          medicine.selected_rxchange
                                            .rxchange_request_id,
                                          medicine.selected_rxchange
                                            .prescribed_dsp_prescription_id,
                                          medicine.dsp_prescription_id
                                        )
                                      "
                                    >
                                      <p>
                                        {{
                                          medicine.selected_rxchange
                                            .rxchange_type
                                        }}
                                      </p>
                                      <div class="row">
                                        <div class="col-6">
                                          <button
                                            @click="
                                              showRejectRxChangeModal(
                                                medicine.selected_rxchange
                                                  .rxchange_request_id
                                              )
                                            "
                                            type="button"
                                            class="btn btn-sm bg-warning-light btn-block"
                                          >
                                            {{
                                              $t(
                                                "provider.prescriptionDetails.medications.medicines.denyRxChange"
                                              )
                                            }}
                                          </button>
                                        </div>
                                        <div class="col-6">
                                          <button
                                            type="submit"
                                            class="btn btn-sm bg-success-light btn-block"
                                          >
                                            {{
                                              $t(
                                                "provider.prescriptionDetails.medications.medicines.approveRxChange"
                                              )
                                            }}
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>

                              <!-- Prescription Details -->
                              <div
                                class="card"
                                :style="
                                  isOutOfStock(medicine.selected_rxchange)
                                    ? `background: #eeeeee;`
                                    : ``
                                "
                              >
                                <div class="card-header">
                                  <div class="row">
                                    <div class="col-8">
                                      <h5>
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.referenceId"
                                          )
                                        }}:
                                        {{
                                          medicine.selected_refill
                                            ? medicine.selected_refill
                                                .dsp_prescription_id
                                            : medicine.dsp_prescription_id
                                        }}
                                      </h5>
                                      <h6>
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.createdDate"
                                          )
                                        }}:
                                        {{
                                          medicine.selected_refill
                                            ? new Date(
                                                medicine.selected_refill.written_date
                                              ).toLocaleString()
                                            : new Date(
                                                medicine.created_at
                                              ).toLocaleString()
                                        }}
                                      </h6>
                                    </div>
                                    <div class="col-4 text-right">
                                      <button
                                        class="btn btn-sm bg-danger-light btn-block"
                                        v-if="
                                          medicine.selected_rxchange &&
                                          medicine.selected_rxchange
                                            .rxchange_type === 'Out Of Stock'
                                        "
                                      >
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.expired"
                                          )
                                        }}
                                      </button>
                                      <button
                                        v-else
                                        class="btn btn-sm bg-info-light btn-block"
                                        @click="
                                          setPrintStatus(
                                            prescriptionDetails.patient
                                              .dsp_user_id,
                                            medicine.dsp_prescription_id
                                          )
                                        "
                                      >
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.print"
                                          )
                                        }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="card-body"
                                  v-if="
                                    !medicine.selected_refill &&
                                    !medicine.pharmacy_refills
                                  "
                                >
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.quantity"
                                            )
                                          }}</strong></label
                                        >
                                        <input
                                          type="number"
                                          class="form-control form-control-xs"
                                          @keyup="updatePrescriptionRx"
                                          @change="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          v-model="medicine.quantity"
                                          min="1"
                                          max="5000"
                                          step="any"
                                          :disabled="isEditingAllowed(medicine)"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.unit"
                                            )
                                          }}</strong></label
                                        >
                                        <select
                                          class="form-control select form-control-xs"
                                          tabindex="-1"
                                          aria-hidden="true"
                                          v-model="medicine.unit_id"
                                          required
                                          @change="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          :disabled="isEditingAllowed(medicine)"
                                        >
                                          <option
                                            v-for="(unit, i) in units"
                                            :key="i"
                                            :value="
                                              unit.StandardDispenseUnitTypeID
                                            "
                                          >
                                            {{ unit.Plural }} ({{
                                              unit.Abbreviation
                                            }})
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.refillsRequested"
                                            )
                                          }}</strong></label
                                        >
                                        <input
                                          type="number"
                                          class="form-control form-control-xs"
                                          @keyup="updatePrescriptionRx"
                                          v-model="medicine.refills"
                                          @change="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          min="0"
                                          max="99"
                                          :disabled="isEditingAllowed(medicine)"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.daysSupply"
                                            )
                                          }}</strong></label
                                        >
                                        <input
                                          type="number"
                                          class="form-control form-control-xs"
                                          @keyup="updatePrescriptionRx"
                                          v-model="medicine.supply_days"
                                          @change="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          min="1"
                                          max="999"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.description"
                                            )
                                          }}</strong></label
                                        >
                                        <textarea
                                          class="form-control form-control-xs"
                                          @keyup="updatePrescriptionRx"
                                          @keydown="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          v-model="medicine.directions"
                                          placeholder="Directions of use"
                                          :disabled="isEditingAllowed(medicine)"
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.pharmacyNotes"
                                            )
                                          }}</strong></label
                                        >
                                        <textarea
                                          class="form-control form-control-xs"
                                          @keyup="updatePrescriptionRx"
                                          @keydown="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          v-model="medicine.pharmacy_note"
                                          placeholder="Notes to Pharmacy"
                                          :disabled="isEditingAllowed(medicine)"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row" v-if="isEditing">
                                    <div class="col-12">
                                      <button
                                        @click="savePrescriptionDetails"
                                        class="btn btn-sm bg-info-light btn-block"
                                      >
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.savePrescription"
                                          )
                                        }}
                                      </button>
                                    </div>
                                  </div>
                                  <!-- RxChange Buttons -->
                                  <div
                                    class="row"
                                    v-if="
                                      !isEditing &&
                                      medicine.is_rxchange_requested &&
                                      medicine.selected_rxchange &&
                                      medicine.selected_rxchange.status ===
                                        'Pending'
                                    "
                                  >
                                    <div class="col-6">
                                      <button
                                        class="btn btn-sm bg-warning-light btn-block"
                                      >
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.denyRxChange"
                                          )
                                        }}
                                      </button>
                                    </div>
                                    <div class="col-6">
                                      <button
                                        @click="
                                          approveRxChangeRequest(
                                            medicine.selected_rxchange
                                              .rxchange_request_id,
                                            medicine.selected_rxchange
                                              .prescribed_dsp_prescription_id,
                                            medicine.dsp_prescription_id
                                          )
                                        "
                                        class="btn btn-sm bg-success-light btn-block"
                                      >
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.approveRxChange"
                                          )
                                        }}
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <!-- Refill Object -->
                                <div
                                  class="card-body"
                                  v-if="
                                    medicine.selected_refill &&
                                    medicine.pharmacy_refills
                                  "
                                >
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.quantity"
                                            )
                                          }}</strong></label
                                        >
                                        <input
                                          type="number"
                                          class="form-control form-control-xs"
                                          @keyup="updatePrescriptionRx"
                                          @change="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          v-model="
                                            medicine.selected_refill.quantity
                                          "
                                          min="1"
                                          max="5000"
                                          step="any"
                                          :disabled="isEditingAllowed(medicine)"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.unit"
                                            )
                                          }}</strong></label
                                        >
                                        <select
                                          class="form-control select form-control-xs"
                                          tabindex="-1"
                                          aria-hidden="true"
                                          v-model="
                                            medicine.selected_refill.unit_id
                                          "
                                          required
                                          @change="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          :disabled="isEditingAllowed(medicine)"
                                        >
                                          <option
                                            v-for="(unit, i) in units"
                                            :key="i"
                                            :value="
                                              unit.StandardDispenseUnitTypeID
                                            "
                                          >
                                            {{ unit.Plural }} ({{
                                              unit.Abbreviation
                                            }})
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.refillsRequested"
                                            )
                                          }}</strong></label
                                        >
                                        <input
                                          type="number"
                                          class="form-control form-control-xs"
                                          @keyup="updatePrescriptionRx"
                                          v-model="
                                            medicine.selected_refill.refills
                                          "
                                          @change="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          min="0"
                                          max="99"
                                          :disabled="isEditingAllowed(medicine)"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.daysSupply"
                                            )
                                          }}</strong></label
                                        >
                                        <input
                                          type="number"
                                          class="form-control form-control-xs"
                                          @keyup="updatePrescriptionRx"
                                          v-model="
                                            medicine.selected_refill.supply_days
                                          "
                                          @change="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          min="1"
                                          max="999"
                                          :disabled="isEditingAllowed(medicine)"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.description"
                                            )
                                          }}</strong></label
                                        >
                                        <textarea
                                          class="form-control form-control-xs"
                                          @keyup="updatePrescriptionRx"
                                          @keydown="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          v-model="
                                            medicine.selected_refill.directions
                                          "
                                          placeholder="Directions of use"
                                          :disabled="isEditingAllowed(medicine)"
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.pharmacyNotes"
                                            )
                                          }}</strong></label
                                        >
                                        <textarea
                                          class="form-control form-control-xs"
                                          @keyup="updatePrescriptionRx"
                                          @keydown="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          v-model="
                                            medicine.selected_refill
                                              .pharmacy_note
                                          "
                                          placeholder="Notes to Pharmacy"
                                          :disabled="isEditingAllowed(medicine)"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="row"
                                    v-if="
                                      !isEditing &&
                                      medicine.selected_refill.status !==
                                        'Completed' &&
                                      medicine.selected_refill.status !==
                                        'Denied'
                                    "
                                  >
                                    <div class="col-6">
                                      <button
                                        @click="
                                          showQuickDenyModal(
                                            medicine.prescription_id,
                                            medicine.selected_refill.refill_id
                                          )
                                        "
                                        class="btn btn-sm bg-warning-light btn-block"
                                      >
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.denyRefill"
                                          )
                                        }}
                                      </button>
                                    </div>
                                    <div class="col-6">
                                      <button
                                        @click="
                                          showQuickApproveModal(
                                            medicine.prescription_id,
                                            medicine.selected_refill.refill_id
                                          )
                                        "
                                        class="btn btn-sm bg-success-light btn-block"
                                      >
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.approveRefill"
                                          )
                                        }}
                                      </button>
                                    </div>
                                  </div>
                                  <div class="row" v-if="isEditing">
                                    <div class="col-12">
                                      <button
                                        @click="savePrescriptionDetails"
                                        class="btn bg-info-light btn-block"
                                      >
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.savePrescription"
                                          )
                                        }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- New Medicine Details -->
                            <div
                              class="col-6"
                              v-if="
                                isSpecialRxChange(medicine.selected_rxchange)
                              "
                            >
                              <!-- Prescription Details -->
                              <div
                                class="card"
                                v-if="isOutOfStock(medicine.selected_rxchange)"
                              >
                                <div class="card-header">
                                  <div class="row">
                                    <div class="col-8">
                                      <h6>
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.newReferenceId"
                                          )
                                        }}:
                                        {{
                                          medicine.selected_rxchange
                                            .suggestions[0]
                                            ? medicine.selected_rxchange
                                                .suggestions[0]
                                                .dsp_prescription_id
                                            : medicine.dsp_prescription_id
                                        }}
                                      </h6>
                                      <h6>
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.createdDate"
                                          )
                                        }}:
                                        {{
                                          medicine.selected_rxchange
                                            .suggestions[0]
                                            ? new Date(
                                                medicine.selected_rxchange.suggestions[0].written_date
                                              ).toLocaleString()
                                            : new Date(
                                                medicine.created_at
                                              ).toLocaleString()
                                        }}
                                      </h6>
                                    </div>
                                    <div class="col-4 text-right"></div>
                                  </div>
                                </div>
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.quantity"
                                            )
                                          }}</strong></label
                                        >
                                        <input
                                          type="number"
                                          class="form-control form-control-xs"
                                          v-model="
                                            medicine.selected_rxchange
                                              .suggestions[0].quantity
                                          "
                                          min="1"
                                          max="5000"
                                          step="any"
                                          :disabled="isEditingAllowed(medicine)"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.unit"
                                            )
                                          }}</strong></label
                                        >
                                        <select
                                          class="form-control select form-control-xs"
                                          tabindex="-1"
                                          aria-hidden="true"
                                          v-model="
                                            medicine.selected_rxchange
                                              .suggestions[0].unit_id
                                          "
                                          required
                                          :disabled="isEditingAllowed(medicine)"
                                        >
                                          <option
                                            v-for="(unit, i) in units"
                                            :key="i"
                                            :value="
                                              unit.StandardDispenseUnitTypeID
                                            "
                                          >
                                            {{ unit.Plural }} ({{
                                              unit.Abbreviation
                                            }})
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.refillsRequested"
                                            )
                                          }}</strong></label
                                        >
                                        <input
                                          type="number"
                                          class="form-control form-control-xs"
                                          v-model="
                                            medicine.selected_rxchange
                                              .suggestions[0].refills
                                          "
                                          min="0"
                                          max="99"
                                          :disabled="isEditingAllowed(medicine)"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.daysSupply"
                                            )
                                          }}</strong></label
                                        >
                                        <input
                                          type="number"
                                          class="form-control form-control-xs"
                                          v-model="
                                            medicine.selected_rxchange
                                              .suggestions[0].supply_days
                                          "
                                          min="1"
                                          max="999"
                                          :disabled="isEditingAllowed(medicine)"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.description"
                                            )
                                          }}</strong></label
                                        >
                                        <textarea
                                          class="form-control form-control-xs"
                                          v-model="
                                            medicine.selected_rxchange
                                              .suggestions[0].directions
                                          "
                                          placeholder="Directions of use"
                                          :disabled="isEditingAllowed(medicine)"
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.pharmacyNotes"
                                            )
                                          }}</strong></label
                                        >
                                        <textarea
                                          class="form-control form-control-xs"
                                          v-model="
                                            medicine.selected_rxchange
                                              .suggestions[0].pharmacy_note
                                          "
                                          placeholder="Notes to Pharmacy"
                                          :disabled="isEditingAllowed(medicine)"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6">
                                      <button
                                        class="btn btn-sm bg-warning-light btn-block"
                                      >
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.denyRxChange"
                                          )
                                        }}
                                      </button>
                                    </div>
                                    <div class="col-6">
                                      <button
                                        @click="
                                          approveRxChangeRequest(
                                            medicine.selected_rxchange
                                              .rxchange_request_id,
                                            medicine.selected_rxchange
                                              .suggestions[0]
                                              .dsp_prescription_id,
                                            medicine.dsp_prescription_id
                                          )
                                        "
                                        class="btn btn-sm bg-success-light btn-block"
                                      >
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.approveRxChange"
                                          )
                                        }}
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <!-- Refill Object -->
                                <div
                                  class="card-body"
                                  v-if="
                                    medicine.selected_refill &&
                                    medicine.pharmacy_refills
                                  "
                                >
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.quantity"
                                            )
                                          }}</strong></label
                                        >
                                        <input
                                          type="number"
                                          class="form-control form-control-xs"
                                          @keyup="updatePrescriptionRx"
                                          @change="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          v-model="
                                            medicine.selected_refill.quantity
                                          "
                                          min="1"
                                          max="5000"
                                          step="any"
                                          :disabled="isEditingAllowed(medicine)"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.unit"
                                            )
                                          }}</strong></label
                                        >
                                        <select
                                          class="form-control select form-control-xs"
                                          tabindex="-1"
                                          aria-hidden="true"
                                          v-model="
                                            medicine.selected_refill.unit_id
                                          "
                                          required
                                          @change="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          :disabled="isEditingAllowed(medicine)"
                                        >
                                          <option
                                            v-for="(unit, i) in units"
                                            :key="i"
                                            :value="
                                              unit.StandardDispenseUnitTypeID
                                            "
                                          >
                                            {{ unit.Plural }} ({{
                                              unit.Abbreviation
                                            }})
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.refillsRequested"
                                            )
                                          }}</strong></label
                                        >
                                        <input
                                          type="number"
                                          class="form-control form-control-xs"
                                          @keyup="updatePrescriptionRx"
                                          v-model="
                                            medicine.selected_refill.refills
                                          "
                                          @change="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          min="0"
                                          max="99"
                                          :disabled="isEditingAllowed(medicine)"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.daysSupply"
                                            )
                                          }}</strong></label
                                        >
                                        <input
                                          type="number"
                                          class="form-control form-control-xs"
                                          @keyup="updatePrescriptionRx"
                                          v-model="
                                            medicine.selected_refill.supply_days
                                          "
                                          @change="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          min="1"
                                          max="999"
                                          :disabled="isEditingAllowed(medicine)"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.description"
                                            )
                                          }}</strong></label
                                        >
                                        <textarea
                                          class="form-control form-control-xs"
                                          @keyup="updatePrescriptionRx"
                                          @keydown="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          v-model="
                                            medicine.selected_refill.directions
                                          "
                                          placeholder="Directions of use"
                                          :disabled="isEditingAllowed(medicine)"
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group text-left">
                                        <label
                                          ><strong>{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.pharmacyNotes"
                                            )
                                          }}</strong></label
                                        >
                                        <textarea
                                          class="form-control form-control-xs"
                                          @keyup="updatePrescriptionRx"
                                          @keydown="
                                            () => {
                                              isEditing = true;
                                            }
                                          "
                                          v-model="
                                            medicine.selected_refill
                                              .pharmacy_note
                                          "
                                          placeholder="Notes to Pharmacy"
                                          :disabled="isEditingAllowed(medicine)"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="row"
                                    v-if="
                                      !isEditing &&
                                      medicine.selected_refill.status !==
                                        'Completed' &&
                                      medicine.selected_refill.status !==
                                        'Denied'
                                    "
                                  >
                                    <div class="col-6">
                                      <button
                                        @click="
                                          showQuickDenyModal(
                                            medicine.prescription_id,
                                            medicine.selected_refill.refill_id
                                          )
                                        "
                                        class="btn btn-sm bg-warning-light btn-block"
                                      >
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.denyRefill"
                                          )
                                        }}
                                      </button>
                                    </div>
                                    <div class="col-6">
                                      <button
                                        @click="
                                          showQuickApproveModal(
                                            medicine.prescription_id,
                                            medicine.selected_refill.refill_id
                                          )
                                        "
                                        class="btn btn-sm bg-success-light btn-block"
                                      >
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.approveRefill"
                                          )
                                        }}
                                      </button>
                                    </div>
                                  </div>
                                  <div class="row" v-if="isEditing">
                                    <div class="col-12">
                                      <button
                                        @click="savePrescriptionDetails"
                                        class="btn bg-info-light btn-block"
                                      >
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.savePrescription"
                                          )
                                        }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- Refills History -->
                            <div
                              class="col-3"
                              v-if="
                                !isSpecialRxChange(medicine.selected_rxchange)
                              "
                            >
                              <div class="card">
                                <div class="card-header">
                                  <h5 class="mb-3 mt-2">
                                    {{
                                      $t(
                                        "provider.prescriptionDetails.medications.medicines.refillsGenerated"
                                      )
                                    }}
                                  </h5>
                                </div>
                                <div
                                  style="overflow: auto; height: 380px"
                                  v-if="
                                    medicine.pharmacy_refills &&
                                    medicine.pharmacy_refills.length > 0
                                  "
                                >
                                  <ul class="list-group">
                                    <li
                                      class="list-group-item"
                                      v-for="(
                                        refill, index
                                      ) in medicine.pharmacy_refills"
                                      :key="index"
                                    >
                                      <p class="mb-2">
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.refillId"
                                          )
                                        }}:
                                        <span> {{ refill.refill_id }}</span>
                                      </p>
                                      <p class="mb-2">
                                        Message ID:
                                        {{ refill.dsp_prescription_id }}
                                      </p>
                                      <p class="mb-2">
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.date"
                                          )
                                        }}:
                                        {{
                                          new Date(
                                            refill.requested_date
                                          ).toLocaleString()
                                        }}
                                      </p>
                                      <p class="mb-2">
                                        Status:
                                        <span
                                          v-if="
                                            medicine.selected_refill
                                              .refill_id === refill.refill_id &&
                                            medicine.selected_refill.status !==
                                              'Completed'
                                          "
                                          class="badge bg-success-light"
                                          >{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.selected"
                                            )
                                          }}</span
                                        >
                                        <span
                                          v-if="
                                            medicine.selected_refill
                                              .refill_id === refill.refill_id &&
                                            medicine.selected_refill.status ===
                                              'Completed'
                                          "
                                          class="badge bg-success-light"
                                          >{{
                                            $t(
                                              "provider.prescriptionDetails.medications.medicines.approved"
                                            )
                                          }}</span
                                        >
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                                <div
                                  v-else
                                  class="text-center"
                                  style="overflow: auto; height: 380px"
                                >
                                  <p class="m-5">
                                    {{
                                      $t(
                                        "provider.prescriptionDetails.medications.medicines.nothingHere"
                                      )
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <!-- RxChange History -->
                            <div
                              class="col-3"
                              v-if="
                                !isSpecialRxChange(medicine.selected_rxchange)
                              "
                            >
                              <div class="card">
                                <div class="card-header">
                                  <h5 class="mb-3 mt-2">
                                    {{
                                      $t(
                                        "provider.prescriptionDetails.medications.medicines.rxChangeRequests"
                                      )
                                    }}
                                  </h5>
                                </div>
                                <div
                                  style="overflow: auto; height: 380px"
                                  v-if="
                                    medicine.pharmacy_rxchanges &&
                                    medicine.pharmacy_rxchanges.length > 0
                                  "
                                >
                                  <ul class="list-group">
                                    <li
                                      class="list-group-item"
                                      v-for="(
                                        rxchange, index
                                      ) in medicine.pharmacy_rxchanges"
                                      :key="index"
                                    >
                                      <p class="mb-2">
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.rxChangeId"
                                          )
                                        }}:
                                        <span>
                                          {{
                                            rxchange.rxchange_request_id
                                          }}</span
                                        >
                                      </p>
                                      <p class="mb-2">
                                        Message ID:
                                        <span>
                                          {{
                                            rxchange.requested_dsp_prescription_id
                                          }}</span
                                        >
                                      </p>
                                      <p class="mb-2">
                                        {{
                                          $t(
                                            "provider.prescriptionDetails.medications.medicines.date"
                                          )
                                        }}:
                                        {{
                                          new Date(
                                            rxchange.requested_date
                                          ).toLocaleString()
                                        }}
                                      </p>
                                      <p class="mb-2">
                                        Status:
                                        <span
                                          :class="
                                            getRxChangeStatusColor(
                                              rxchange.status
                                            )
                                          "
                                          >{{
                                            String(
                                              rxchange.status
                                            ).toUpperCase()
                                          }}</span
                                        >
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                                <div
                                  v-else
                                  class="text-center"
                                  style="overflow: auto; height: 380px"
                                >
                                  <p class="m-5">
                                    {{
                                      $t(
                                        "provider.prescriptionDetails.medications.medicines.nothingHere"
                                      )
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Footer buttons -->
                  <div>
                    <div
                      id="alert-msg-box"
                      class="alert alert-warning alert-dismissible fade"
                      role="alert"
                    >
                      <span id="alert-msg"></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- PRESCRIBE NEW MEDICINES -->
              <div
                class="col-md-9"
                v-else-if="prescriptionDetails.status !== 'Cancelled'"
              >
                <div class="card m-0">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-8">
                        <h4>
                          {{
                            $t(
                              "provider.prescriptionDetails.medications.medicines.prescribeMedicines"
                            )
                          }}
                        </h4>
                      </div>
                      <div class="col-4 text-right">
                        <button
                          class="btn btn-sm btn-outline-info mr-2"
                          @click="markChatRead"
                        >
                          <i class="fa fa-comment mr-1"></i>
                          {{
                            $t(
                              "provider.prescriptionDetails.medications.medicines.chat"
                            )
                          }}
                          <span
                            v-if="unreadMessages > 0"
                            class="badge badge-pill bg-danger-light"
                          >
                            {{ unreadMessages }}
                            {{
                              $t(
                                "provider.prescriptionDetails.medications.medicines.new"
                              )
                            }}</span
                          >
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div
                      class="accordion"
                      style="height: 300px; overflow: auto"
                      id="prescribing-accordion"
                    >
                      <!-- CHECK SYMPTOMS -->
                      <div class="card-header" id="symptoms-details-accordion">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-sm btn-block text-left"
                            type="button"
                            data-toggle="collapse"
                            data-target="#symptoms-details-collapse"
                            aria-expanded="true"
                            aria-controls="symptoms-details-collapse"
                            @click="isSymptomsRead = true"
                          >
                            <span :class="isSymptomsRead ? 'text-success' : ''">
                              {{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.step1"
                                )
                              }}
                              :
                            </span>
                            <span
                              v-if="isSymptomsRead"
                              class="badge badge-pill bg-success inv-badge"
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.completed"
                                )
                              }}</span
                            >
                          </button>
                        </h2>
                      </div>
                      <div
                        id="symptoms-details-collapse"
                        class="collapse"
                        aria-labelledby="symptoms-details-accordion"
                        data-parent="#prescribing-accordion"
                      >
                        <div class="card-body">
                          {{ prescriptionDetails.observations.symptoms }}
                        </div>
                      </div>

                      <!-- SELECT MEDICINES -->
                      <div class="card-header" id="medicine-details-accordion">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-sm btn-block text-left"
                            type="button"
                            data-toggle="collapse"
                            data-target="#medicine-details-collapse"
                            aria-expanded="false"
                            aria-controls="medicine-details-collapse"
                            :disabled="
                              !isSymptomsRead && !prescriptionDetails.medicines
                            "
                            @click="showModal('select-medicine-modal')"
                          >
                            <span
                              :class="
                                prescriptionDetails.medicines
                                  ? 'text-success'
                                  : ''
                              "
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.step2"
                                )
                              }}
                              : </span
                            ><span
                              v-if="prescriptionDetails.medicines"
                              class="badge badge-pill bg-success inv-badge"
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.completed"
                                )
                              }}</span
                            >
                          </button>
                        </h2>
                      </div>

                      <!-- SELECT PHARMACY -->
                      <div class="card-header" id="pharmacy-details-accordion">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-sm btn-block text-left"
                            type="button"
                            data-toggle="collapse"
                            data-target="#pharmacy-details-collapse"
                            aria-expanded="false"
                            aria-controls="pharmacy-details-collapse"
                            @click="showModal('select-pharmacy-modal')"
                          >
                            <span
                              :class="
                                prescriptionDetails.pharmacy
                                  ? 'text-success'
                                  : ''
                              "
                            >
                              {{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.step3"
                                )
                              }}
                            </span>
                            <span
                              v-if="prescriptionDetails.pharmacy"
                              class="badge badge-pill bg-success inv-badge"
                              >{{
                                $t(
                                  "provider.prescriptionDetails.medications.medicines.completed"
                                )
                              }}</span
                            >
                          </button>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- REJECTED STATUS -->
              <div class="col-md-9" v-else>
                <div class="text-center p-5">
                  <img
                    class="mb-4"
                    src="../../assets/img/cancel.png"
                    alt="cancel"
                    width="300"
                  />
                  <h3 class="text-muted">
                    {{
                      $t(
                        "provider.prescriptionDetails.medications.medicines.prescriptionRequestCancelled"
                      )
                    }}
                  </h3>
                  <router-link to="/provider/index">
                    <button class="btn btn-sm bg-primary-light mt-4">
                      {{
                        $t(
                          "provider.prescriptionDetails.medications.medicines.backToDashboard"
                        )
                      }}
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Prescription Accept Modal -->
    <div
      class="modal fade"
      id="prescription-accept-modal"
      style="display: none"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <AcceptModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="acceptPrescription"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Prescription Approve Modal -->
    <div
      class="modal fade"
      id="prescription-approve-modal"
      style="display: none"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <ApproveModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="approveAllRefills"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Prescription Cancel Modal -->
    <div
      class="modal fade"
      id="prescription-cancel-modal"
      style="display: none"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <RejectModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @cancel="rejectPrescription"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Prescription Cancel RX Change Modal -->
    <div
      class="modal fade"
      id="prescription-rxcancel-modal"
      style="display: none"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <DenyRxChangeModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              :selectedRxChangeId="selectedRxChangeId"
              @submit="denyRxChangeRequest"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Refill Deny Modal -->
    <div class="modal fade" id="prescription-deny-modal" style="display: none">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <DenyModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="denyAllRefills"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Select Medicines Modal -->
    <div class="modal fade" id="select-medicine-modal" style="display: none">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <SelectMedicineModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="saveMedicines"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Chat Box Modal -->
    <div data-backdrop="static" id="chat-modal" class="slideout">
      <Chat
        v-if="prescriptionDetails"
        isCallingEnabled="true"
        :patientName="`${prescriptionDetails.patient.first_name} ${prescriptionDetails.patient.last_name}`"
        :patientImage="prescriptionDetails.patient.profile_image_url"
        :prescriptionId="prescriptionId"
        :status="prescriptionDetails.status"
        @closechat="closeChat"
      />
    </div>

    <!-- Select Pharmacy Modal -->
    <div class="modal fade" id="select-pharmacy-modal" style="display: none">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <SelectPharmacyModal
              v-if="prescriptionDetails"
              :prescriptionDetails="prescriptionDetails"
              @submit="savePharmacy"
            />
          </div>
        </div>
      </div>
    </div>

    <layout-footer></layout-footer>
  </div>
</template>
<script>
import axios from "axios";
import config from "../../constants/config";
import { mapActions } from "vuex";
import commonMixin from "../../mixins/common";
import AcceptModal from "./AcceptModal.vue";
import ApproveModal from "./ApproveModal.vue";
import RejectModal from "./RejectModal.vue";
import DenyRxChangeModal from "./DenyRxChangeModal.vue";
import DenyModal from "./DenyModal.vue";
import Chat from "./Chat.vue";
import SelectMedicineModal from "./SelectMedicineModal.vue";
import SelectPharmacyModal from "./SelectPharmacyModal.vue";
import Swal from "sweetalert2";

export default {
  props: {
    prescriptionId: String,
  },
  components: {
    AcceptModal: AcceptModal,
    RejectModal: RejectModal,
    ApproveModal: ApproveModal,
    DenyRxChangeModal: DenyRxChangeModal,
    DenyModal: DenyModal,
    SelectMedicineModal: SelectMedicineModal,
    SelectPharmacyModal: SelectPharmacyModal,
    Chat: Chat,
  },
  mixins: [commonMixin],
  computed: {
    chatMessages() {
      return this.$store.getters.chatMessages;
    },
    unreadMessages() {
      return this.$store.getters.chatMessages
        ? this.$store.getters.chatMessages
            .filter((d) => d.user_id !== this.$store.getters.user.id)
            .filter((c) => c.is_read === false).length
        : 0;
    },
    chatEnabled() {
      return this.prescriptionDetails.status === "Completed" ||
        this.prescriptionDetails.status === "Cancelled"
        ? false
        : true;
    },
    route() {
      return this.$route.name;
    },
  },
  data() {
    return {
      prescriptionDetails: null,
      medicineDetails: null,
      isError: true,
      isEditing: false,
      isPharmacyVerified: false,
      newPrescribedMedicine: null,
      rxChangeRequestObj: null,
      units: [],
      isEditingMode: false,
      is_rxchange_requested: false,
      selectedRxChangeId: null,
      showFooterControls: false,
      isSymptomsRead: false,
      priorAuthNumber: "",
      interval: null,
      isChatOpen: false,
    };
  },
  async created() {
    this.showLoader(true);
    const r = await this.getDispenseUnits();
    this.units = r
      .sort((a, b) => a.Name.localeCompare(b.Name))
      .filter((e) => e.IsActive === true);

    const res = await axios.get(
      `${config.BASE_URL}${config.HTTP_API.providerGetPrescriptionById}/${this.prescriptionId}`
    );
    this.prescriptionDetails = res.data;

    this.medicineDetails = this.prescriptionDetails.medicines
      ? this.prescriptionDetails.medicines
      : [];
    const obj = this.medicineDetails.filter((m) => m.is_rxchange_requested);
    if (obj.length > 0) this.is_rxchange_requested = true;

    this.setEditModeState();

    // CHAT MESSAGE REQUESTS
    this.$socket.emit("REQUEST", {
      command: "GET:CHAT_MESSAGES",
      userId: this.$store.getters.user.id,
      prescriptionId: this.prescriptionId,
    });

    if (this.chatEnabled) {
      this.interval = setInterval(() => {
        if (!this.chatEnabled) {
          if (this.interval) clearInterval(this.interval);
          this.interval = null;
          return;
        }

        this.$socket.emit("REQUEST", {
          command: "GET:CHAT_MESSAGES",
          userId: this.$store.getters.user.id,
          prescriptionId: this.prescriptionId,
        });
      }, 2000);
    } else {
      if (this.interval) clearInterval(this.interval);
      this.interval = null;
    }
    this.showLoader(false);
  },
  watch: {
    medicineDetails: function () {
      this.updatePrescriptionRx();
    },
    route: function () {
      if (this.route !== "view-prescription") {
        if (this.interval) clearInterval(this.interval);
        this.interval = null;
        this.$socket.emit("REQUEST", {
          command: "GET:CLEAR_CHAT",
          userId: this.$store.getters.user.id,
        });
      }
    },
    chatMessages: function (newVal, oldVal) {
      if (newVal.length != oldVal.length && this.isChatOpen) {
        this.$socket.emit("REQUEST", {
          command: "POST:CHAT_MESSAGES_MARK_READ",
          userId: this.$store.getters.user.id,
          prescriptionId: this.prescriptionId,
        });
      }
    },
  },
  methods: {
    ...mapActions(["showLoader"]),

    closeChat() {
      this.isChatOpen = false;
      // this.hideModal("chat-modal");
      $(".slideout").removeClass("on");
    },

    markChatRead() {
      this.$socket.emit("REQUEST", {
        command: "POST:CHAT_MESSAGES_MARK_READ",
        userId: this.$store.getters.user.id,
        prescriptionId: this.prescriptionId,
      });

      this.isChatOpen = true;
      // this.showModal("chat-modal");
      $(".slideout").addClass("on");
    },

    isEditingAllowed(medicine) {
      return (
        !this.isEditingMode ||
        (medicine.is_rxchange_requested &&
          medicine.selected_rxchange.rxchange_type === "Out Of Stock") ||
        (medicine.pharmacy_refills && medicine.pharmacy_refills.length > 0)
      );
    },

    isOutOfStock(selectedRxChange) {
      if (!selectedRxChange) return false;
      let type = String(selectedRxChange.rxchange_type).toLowerCase();
      if (type === "out of stock") return true;
      return false;
    },

    isSpecialRxChange(selectedRxChange) {
      if (!selectedRxChange) return false;
      let type = String(selectedRxChange.rxchange_type).toLowerCase();
      if (type === "out of stock") return true;
      return false;
    },

    showModal(modalName) {
      $("#" + modalName).modal("show");
    },

    hideModal(modalName) {
      $("#" + modalName).modal("hide");
    },

    setFooterControlDisplay(type) {
      if (type === "Script Clarification") this.showFooterControls = true;
      else this.showFooterControls = false;
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },

    async reconcilePrescription(prescriptionId) {
      try {
        this.showLoader(true);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerReconcilePrescription}`,
          {
            prescriptionId: prescriptionId,
          }
        );

        Swal.fire(
          "Prescription Reconciled!",
          "Prescription has been reconciled successfully",
          "success"
        ).then(() => {
          this.$router.go();
        });
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire("Reconcile Failed!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    async setPrintStatus(dspPatientId, prescriptionId) {
      Swal.fire({
        icon: "info",
        titleText: "Print Confirmation",
        text: `Do you want to set the prescription to printed?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        customClass: {
          confirmButton: "bg-success-light",
          denyButton: "bg-danger-light",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          // SEND REFILL DENIAL
          try {
            this.showLoader(true);
            await axios.post(
              `${config.BASE_URL}${config.HTTP_API.providerPrintPrescription}`,
              {
                prescriptionId: prescriptionId,
                dspPatientId: dspPatientId,
              }
            );

            Swal.fire(
              "Status Updated",
              "Prescription status has been set to printed",
              "success"
            ).then(() => {
              this.$router.go();
            });

            this.showLoader(false);
          } catch (e) {
            console.error(e);
            Swal.fire("Print Failed!", e.response.data.message, "error");
            this.showLoader(false);
          }
        }
      });
    },

    selectNewMedicine(allMedicines, selectedKey) {
      // Change btn color
      for (let i = 0; i < allMedicines.length; i++) {
        if (i === selectedKey) {
          const btn = document.getElementById(`select-btn-${i}`);
          if (btn) btn.classList = "btn btn-sm btn-info btn-block";
        } else {
          const btn = document.getElementById(`select-btn-${i}`);
          if (btn) btn.classList = "btn btn-sm bg-info-light btn-block";
        }
      }

      this.newSelectedMedicine = allMedicines[selectedKey];
      this.showFooterControls = true;
    },

    isEditingRequired(medicine) {
      if (medicine.rxChange)
        this.setFooterControlDisplay(medicine.rxChange.rxChangeType);

      if (!medicine.is_rxchange_requested && medicine.dsp_status_id == 13) {
        this.isEditingMode = false;
      }

      if (
        medicine.is_rxchange_requested ||
        (medicine.is_refill_initiated &&
          this.prescriptionDetails.status !== "Completed")
      ) {
        this.isEditingMode = true;
      }
    },

    setEditModeState() {
      if (
        this.prescriptionDetails.status === "Cancelled" ||
        this.prescriptionDetails.status === "Accepted"
      ) {
        this.isEditingMode = false;
      } else {
        this.isEditingMode = true;
      }
    },

    async approveRxChangeRequest(
      rxChangeRequestId,
      newPrescriptionId,
      originalPrescriptionId
    ) {
      try {
        this.showLoader(true);

        let type = "";
        if (this.priorAuthNumber) type = "Prior";
        else if (this.npiVerification) type = "Prescriber";
        else type = "General";

        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerApproveRxChangeRequest}${rxChangeRequestId}`,
          {
            newPrescriptionId: this.newSelectedMedicine
              ? this.newSelectedMedicine.PrescriptionId
              : newPrescriptionId,
            originalPrescriptionId: originalPrescriptionId,
            npiVerification: this.npiVerification,
            priorAuthNumber: this.priorAuthNumber,
            type: type,
          }
        );
        this.npiVerification = "";
        this.newSelectedMedicine = null;

        Swal.fire(
          "RxChange Completed!",
          "RxChange request has been approved.",
          "success"
        ).then(() => {
          this.$router.go();
        });
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        this.showLoader(false);
        Swal.fire("Approval Failed!", e.response.data.message, "error");
      }
    },

    async savePharmacy(pharmacyDetails) {
      try {
        this.showLoader(true);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerSavePrescription}${this.prescriptionId}`,
          {
            pharmacy: JSON.parse(pharmacyDetails),
          }
        );

        this.showLoader(false);
        this.isEditing = false;
        this.hideModal("select-pharmacy-modal");
        Swal.fire(
          "Saved!",
          "Pharmacy assigned to the prescription",
          "success"
        ).then(() => {
          this.$router.go();
        });
      } catch (e) {
        console.error(e.response.message);
        this.showLoader(false);
        Swal.fire("Failed!", e.response.data.message, "error");
      }
    },

    async saveMedicines(medicines) {
      try {
        this.showLoader(true);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerSavePrescription}${this.prescriptionId}`,
          {
            medicines: JSON.parse(medicines),
          }
        );

        this.showLoader(false);
        this.isEditing = false;
        this.hideModal("select-medicine-modal");
        Swal.fire("Saved!", "Prescription has been saved", "success").then(
          () => {
            this.$router.go();
          }
        );
      } catch (e) {
        console.error(e.response.message);
        this.showLoader(false);
        Swal.fire("Failed!", e.response.data.message, "error");
      }
    },

    async savePrescriptionDetails() {
      try {
        this.showLoader(true);
        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerSavePrescription}${this.prescriptionId}`,
          {
            medicines: this.medicineDetails,
          }
        );

        this.showLoader(false);
        this.isEditing = false;
      } catch (e) {
        console.error(e.response.message);
        this.showLoader(false);
      }
    },

    showRejectPrescriptionModal() {
      $("#prescription-cancel-modal").modal("toggle");
    },

    showDenyRefillRequestModal() {
      $("#prescription-deny-modal").modal("toggle");
    },

    showAcceptPrescriptionModal() {
      $("#prescription-accept-modal").modal("show");
    },

    showApprovePrescriptionModal() {
      $("#prescription-approve-modal").modal("toggle");
    },

    showRejectRxChangeModal(rxChangeId) {
      this.selectedRxChangeId = rxChangeId;
      $("#prescription-rxcancel-modal").modal("toggle");
    },

    showQuickApproveModal(prescriptionId, refillId) {
      Swal.fire({
        icon: "info",
        titleText: "Need Approval Confirmation",
        text: `Do you want to approve the refill request ID: ${refillId} ?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        customClass: {
          confirmButton: "bg-success",
          denyButton: "bg-danger-light",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // SEND REFILL APPROVAL
          this.approveSingleRefill(prescriptionId, refillId);
        }
      });
    },

    showQuickDenyModal(prescriptionId, refillId) {
      Swal.fire({
        icon: "info",
        titleText: "Need Denial Confirmation",
        text: `Do you want to deny the refill request ID: ${refillId}?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        customClass: {
          confirmButton: "bg-success",
          denyButton: "bg-danger-light",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // SEND REFILL DENIAL
          this.denySingleRefill(prescriptionId, refillId);
        }
      });
    },

    updatePrescriptionRx() {
      const alertBox = document.getElementById("alert-msg-box");
      const alertMsg = document.getElementById("alert-msg");
      const directionsArray = this.medicineDetails.filter((m) => !m.directions);
      if (directionsArray.length > 0) {
        if (alertBox) {
          alertBox.classList =
            "alert alert-warning alert-dismissible fade show";
        }
        if (alertMsg)
          alertMsg.innerText =
            "Please enter Directions of use and pharmacy notes";
        this.isError = true;
        return;
      }

      const quantityArray = this.medicineDetails.filter(
        (m) => !m.quantity || m.quantity == 0
      );
      if (quantityArray.length > 0) {
        if (alertBox) {
          alertBox.classList =
            "alert alert-warning alert-dismissible fade show";
        }
        if (alertMsg)
          alertMsg.innerText =
            "Please enter Quantity for " +
            quantityArray.map((e) => e.name).join(", ");
        this.isError = true;
        return;
      }

      const refillsArray = this.medicineDetails.filter(
        (m) => !m.refills || m.refills < 0
      );
      if (refillsArray.length < 0) {
        if (alertBox) {
          alertBox.classList =
            "alert alert-warning alert-dismissible fade show";
        }
        if (alertMsg)
          alertMsg.innerText =
            "Please enter Refills for " +
            refillsArray.map((e) => e.name).join(", ");
        this.isError = true;
        return;
      }

      if (alertBox) {
        alertBox.classList = "alert alert-success alert-dismissible fade show";
      }
      if (alertMsg)
        alertMsg.innerText =
          "Great! Everything looks good. Please save the prescription.";
      this.isError = false;
    },

    getRxChangeMessage(message, extraDetail) {
      switch (message) {
        case "Prescriber Authorization":
          return `Need Provider's Authorization`;

        case "State License Status":
          return `Please verify state license number for state code: ${extraDetail}`;

        default:
          return message;
      }
    },

    async acceptPrescription(prescriptionId) {
      try {
        this.showLoader(true);
        $("#prescription-accept-modal").modal("hide");

        const res = await axios.post(
          `${
            config.BASE_URL + config.HTTP_API.providerAcceptPrescriptions
          }/${prescriptionId}/${this.$store.getters.user.id}`
        );

        Swal.fire(
          "Rx Request Approved!",
          "The prescription has been sent to the pharmacy for approval",
          "success"
        ).then(() => {
          this.$router.go();
        });
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        $("#prescription-accept-modal").modal("hide");
        Swal.fire("Failed!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    async approveSingleRefill(prescriptionId, refillId) {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${
            config.BASE_URL + config.HTTP_API.providerApproveSingleRefill
          }/${prescriptionId}/${refillId}/${this.$store.getters.user.id}`
        );

        Swal.fire(
          "Refill Completed!",
          "Refill request has been approved",
          "success"
        ).then(() => {
          this.$router.go();
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire("Failed!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    async denySingleRefill(prescriptionId, refillId) {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${
            config.BASE_URL + config.HTTP_API.providerDenySingleRefill
          }/${prescriptionId}/${refillId}/${this.$store.getters.user.id}`
        );

        Swal.fire(
          "Refill Denied!",
          "Refill request denied successfully",
          "success"
        ).then(() => {
          this.$router.go();
        });

        this.showLoader(false);
      } catch (e) {
        Swal.fire("Failed!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    async approveAllRefills(prescriptionId) {
      try {
        this.showLoader(true);
        $("#prescription-approve-modal").modal("hide");
        const res = await axios.post(
          `${
            config.BASE_URL + config.HTTP_API.providerApproveAllRefills
          }/${prescriptionId}/${this.$store.getters.user.id}`
        );

        Swal.fire(
          "Completed!",
          "Refill request has been approved",
          "success"
        ).then(() => {
          this.$router.go();
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire("Failed!", e.response.data.message, "error");
        $("#prescription-approve-modal").modal("hide");
        this.showLoader(false);
      }
    },

    async denyAllRefills(prescriptionId) {
      try {
        const pres = JSON.parse(prescription);
        this.showLoader(true);

        $("#prescription-deny-modal").modal("hide");

        const res = await axios.post(
          `${
            config.BASE_URL + config.HTTP_API.providerDenyAllRefills
          }/${prescriptionId}/${this.$store.getters.user.id}`,
          {
            reason: pres.reason,
          }
        );

        Swal.fire(
          "Refill Denied!",
          "Refill request denied successfully",
          "success"
        ).then(() => {
          this.$router.go();
        });

        this.showLoader(false);
      } catch (e) {
        $("#prescription-deny-modal").modal("hide");
        Swal.fire("Failed!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    async rejectPrescription(prescription) {
      try {
        const pres = JSON.parse(prescription);
        this.showLoader(true);
        $("#prescription-cancel-modal").modal("hide");

        const res = await axios.post(
          `${config.BASE_URL + config.HTTP_API.providerRejectPrescriptions}/${
            pres.id
          }/${this.$store.getters.user.id}`,
          {
            reason: pres.reason,
          }
        );

        Swal.fire(
          "Success!",
          "Prescription request rejected successfully",
          "success"
        ).then(() => {
          this.$router.go();
        });
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        $("#prescription-cancel-modal").modal("hide");
        Swal.fire("Cancellation Failed!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    async denyRxChangeRequest(rejectObj) {
      try {
        this.showLoader(true);
        const data = JSON.parse(rejectObj);

        $("#prescription-rxcancel-modal").modal("hide");

        await axios.post(
          `${config.BASE_URL}${config.HTTP_API.providerDenyRxChangeRequest}${data.rxChangeId}`,
          {
            rxChangeRequestId: data.rxChangeId,
            reason: data.reason,
          }
        );

        Swal.fire("Success!", "RxChange denied successfully", "success").then(
          () => {
            this.$router.go();
          }
        );
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        $("#prescription-rxcancel-modal").modal("hide");
        Swal.fire("Failed!", e.response.data.message, "error").then(() => {
          this.$router.go();
        });
        this.showLoader(false);
      }
    },

    getStatusColor(status) {
      // console.log(status);
      switch (status) {
        case "Pending":
          return "btn bg-secondary-light ml-2";

        case "In Progress":
          return "btn bg-warning-light ml-2";

        case "Completed":
        case "Accepted":
          return "btn bg-success-light ml-2";

        case "Cancelled":
          return "btn bg-danger-light ml-2";

        default:
          return "btn bg-warning-light ml-2";
      }
    },

    getRxStatusColor(status) {
      switch (status) {
        case "Pending":
          return "fas text-warning fa-refresh mr-2";

        case "Completed":
          return "fas text-success fa-refresh mr-2";

        case "Denied":
          return "fas text-danger fa-refresh mr-2";

        default:
          return "fas text-warning fa-refresh mr-2";
      }
    },

    getStatusText(status) {
      switch (status) {
        case "Accepted":
          if (
            this.medicineDetails.find(
              (e) =>
                e.dsp_status_id === 0 ||
                e.dsp_status_id === 1 ||
                !e.is_refill_initiated
            )
          ) {
            this.isPharmacyVerified = false;
            return "WAITING FOR PHARMACY CONFIRMATION";
          }

          this.isPharmacyVerified = true;
          return "PHARMACY VERIFIED";

        case "Cancelled":
          return this.medicineDetails.filter(
            (i) => i.dspCancellationStatus == "In Process"
          ).length > 0
            ? "Cancellation In Process"
            : "Cancelled";

        case "Error":
          return "Error / Cancelled";

        default:
          return status;
      }
    },

    getRxChangeStatusColor(status) {
      switch (status) {
        case "Pending":
          return "badge badge-pill bg-warning-light";

        case "Completed":
          return "badge badge-pill bg-success-light";

        case "Denied":
          return "badge badge-pill bg-danger-light";

        case "Obsolete":
          return "badge badge-pill bg-secondary-light";

        default:
          return "";
      }
    },
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
    this.interval = null;
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .webViewTable {
    display: none;
  }

  .mobileView {
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    /* Added */
  }
}

@media only screen and (min-width: 600px) {
  .mobileView {
    display: none;
  }
}

.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

p {
  font: 1.2em sans-serif;
}

.form-control-xs::placeholder {
  font-size: 0.75rem !important;
}

.mycontent-left {
  border-right: 1px solid rgb(194, 194, 194);
}

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}

.slideout {
  background: linear-gradient(to bottom, #15558d 0%, #15558d 100%);
  color: #fff;
  height: 100%;
  padding-left: 2px;
  position: fixed;
  right: -500px;
  top: 0;
  transition-duration: 0.2s;
  width: 500px;
  z-index: 9999;
}

.slideout.on {
  right: 0;
}
</style>
